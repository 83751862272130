<template>
  <div
    :class="[
      'app-tooltip',
      { 'app-tooltip--gray': gray },
      { 'app-tooltip--darkgray': darkgray },
      { 'app-tooltip--top': top },
      { 'app-tooltip--left': left },
      { 'app-tooltip--right': right },
      { 'app-tooltip--small': small },
      { 'app-tooltip--clickable': clickable },
      `app-tooltip--z-${zIndex}`,
    ]"
  >
    <div
      v-if="!clickable"
      @mouseover="handleMouseOver"
      @mouseout="handleMouseOut"
      class="app-tooltip__content"
    >
      <slot name="content" />
    </div>
    <div v-else @click="handleClick" class="app-tooltip__content">
      <slot name="content" />
    </div>
    <div class="app-tooltip__tip" v-if="isOpenComputed">
      <div v-if="imageId" :id="imageId" class="w-[288px] h-[169px]"></div>
      <h5
        v-if="title"
        :class="['app-tooltip__title', isEditorPreview && 'text-left mt-5']"
      >
        {{ $t(title) }}
      </h5>
      <span :class="['app-tooltip__text', isEditorPreview && 'text-left']"
        >{{ $t(tip)
        }}<span class="inline-flex ml-1.5 text-[#bbbbbb]" v-if="note">{{
          note
        }}</span></span
      >
      <div class="app-tooltip__footer" v-if="clickable">
        <BaseButton
          :label="'Okay'"
          :type="'secondary'"
          @click="handleClick"
        ></BaseButton>
      </div>
      <div class="app-tooltip__footer" v-if="isEditorPreview">
        <BaseButton
          small
          :label="'Dismiss'"
          :type="'secondary'"
          @click="$emit('dismissCoachmark')"
          style="
            background-color: rgb(89, 101, 123);
            color: white;
            border-color: white;
            margin-right: 7px;
            padding-left: 1em;
            padding-right: 1em;
          "
        ></BaseButton>
        <BaseButton
          small
          :label="'Switch now'"
          :type="'secondary'"
          @click="$emit('showNewEditor')"
          style="border-color: white; padding-left: 1em; padding-right: 1em"
        ></BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  imageId: {
    type: String,
    default: null,
  },
  tip: {
    type: String,
    required: true,
  },
  note: {
    type: String,
    default: null,
  },
  top: {
    type: Boolean,
    default: false,
  },
  left: {
    type: Boolean,
    default: false,
  },
  right: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  gray: {
    type: Boolean,
    default: false,
  },
  darkgray: {
    type: Boolean,
    default: false,
  },
  clickable: {
    type: Boolean,
    default: false,
  },
  zIndex: {
    type: Number,
    default: null,
  },
  showSwitchButton: {
    type: Boolean,
    default: false,
  },
  alwaysOpen: {
    type: Boolean,
    default: false,
  },
  alwaysClosed: {
    type: Boolean,
    default: false,
  },
})

const isOpen = ref(false)

const isOpenComputed = computed(() => {
  return (isOpen.value || props.alwaysOpen) && !props.alwaysClosed
})

function handleMouseOver() {
  isOpen.value = true
}

function handleMouseOut() {
  isOpen.value = false
}

function handleClick() {
  isOpen.value = !isOpen.value
}
</script>

<style></style>
